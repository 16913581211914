

.producto-page-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra el contenido */
    width: 100%;
    max-width: 1200px; /* Define el ancho máximo */
    margin: 0 auto; /* Centra en la pantalla */
    gap: 20px;
    padding: 0px; /* Añade espacio lateral */
    box-sizing: border-box;
}

.product-navigation {
    width: 100%; /* Ocupa todo el ancho del contenedor principal */
    padding: 1rem;
    background-color: #f8f9fa;
    border-bottom: 1px solid #e9ecef;
    display: flex;
    justify-content: center; /* Centra el contenido horizontalmente */
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.producto-detalle {
    display: flex;
    width: 100%;
    gap: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.producto-imagen {
    flex: 0 0 500px;
}

.producto-imagen img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
}

.producto-info {
    flex: 1;
    padding: 20px;
}

.producto-info h1 {
    color: #333;
    margin-bottom: 20px;
    font-size: 24px;
}

.producto-info p {
    color: #666;
    margin-bottom: 10px;
    font-size: 16px;
}

.producto-whatsapp {
    margin-top: 30px;
}

.whatsapp-button {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #25D366;
    color: white;
    padding: 12px 24px;
    border-radius: 8px;
    text-decoration: none;
    font-weight:bold;
    font-size: large;
    transition: background-color 0.3s;
}

.whatsapp-button:hover {
    background-color: #128C7E;
}

.whatsapp-button img {
    width: 54px;
    height: 54px;
}

@media (max-width: 768px) {
    .producto-detalle {
        flex-direction: column;
        margin: 10px;
    }

    .producto-imagen {
        flex: 0 0 auto;
    }

    .producto-info {
        padding: 10px;
    }

    .producto-info h1 {
        font-size: 20px;
    }
}
