*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html{
  font-size: 62.5%;
}
.App {
  text-align: center;
}

.App-header {

}

.App-body{

}
