/* src/Components/ChatGPT/CabeceraChatGPT.css */
.cabecera-container {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #c0c0c0;
    padding: 0px;
  }
  
  .icons-container {
    display: flex;
    gap: 20px;
    margin-left: auto;
    
  }
  
  .icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
  }
  