.marcas-checkbox{
    width: 130px;
    background-color: var(--Fondo_Gris);
 
}
.mycheck{
    cursor: pointer;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    width: 14px;
    height: 14px;
    appearance: none;
    border: 2px solid var(--Fondo_Azul_3);
    margin-top: 2px;
}
.marcas-checkbox ul{
    border-top:  3px solid var(--Texto_Gris_2);
}
.marcas-checkbox ul li{
    padding: 0px 5px 5px 7px;
    border-bottom: 1px solid var(--Texto_Gris_4);
    cursor: pointer; 
}
.mycheck:checked {
    background-color: var(--Fondo_Azul_4);
}