@import url('../../Variables.css');
.menu-lateral{
    width: 130px;
    background-color: var(--Fondo_Azul_5);
 
}
.menu-lateral ul{
    border-top:  3px solid var(--Texto_Gris_2);
}
.menu-lateral ul li{
    padding: 5px;
    border-bottom: 1px solid var(--Texto_Gris_4);
    cursor: pointer; 
}

.menu-lateral ul li:hover{
    background-color: var(--Fondo_Azul_4); /* Color de fondo más oscuro al hacer hover */
    color: var(--Texto_Blanco); /* Cambia el color del texto al hacer hover */
}

.menu-lateral ul li.active {
    background-color: var(--Fondo_Azul_3);
    color: var(--Texto_Blanco); /* Cambia el color del texto al hacer hover */
}