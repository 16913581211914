@import url('../../Variables.css');

.header{
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000; /* Asegúrate de que el encabezado esté sobre otros contenidos */
}
.header_Sup{
    
    height: 48px;
    background-color: var(--Fondo_Azul);
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

  