.marcas {
    text-align: center;
  }
  
  .marcas-cabecera {
    width: 100%;
    height: 124px;
    background-color: #3dce59;
    margin-top: 72px;
  }

  
  .marcas-contenedor-subcategorias {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }
  
  .marcas-subcategorias {
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    width: 200px;
    text-align: center;
  }
  
  .marcas-subcategorias-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .marcas-subcategorias p {

    margin-bottom: 20px;
    font-size: 14px;
    font-weight: bold;
  }
  