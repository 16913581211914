@import url('../../Variables.css');

.footer_movile{
    width: 100%;
    height: 70px;    
    position: absolute;
    bottom: 0;    
    margin: 0;
    padding:0px;
    align-items: center;
}
.footer_movile_carritoCompras{
    width: auto;
    height: 20px;
    background-color: var(--Fondo_Gris);
    color: var(--Texto_Negro);
    border: 1px solid var(--Texto_Gris_4);
    border-radius: 4px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    padding:7px;
    margin-top: 15px;
    font-family: system-ui;
    font-weight: bold;
}
.footer_movile ul{
    height: 40px;
    width: 100%;
    background-color: var(--Naranja_1);
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    padding: 0px;
    margin-top: 37px;
    padding-top: 3px;
}

.footer_movile img{
    position: relative;
}

.footer_desktop{
    display: none;
}

.footer_{
    width: 100%;
    height: auto;
    display:inline;
    margin:0px;
    padding: 0px;
    position:relative;
}
.footer_contenedor {
    width: 100%;
    height: auto;
    background-color: #707070;
    display: flex;
    justify-content: space-between;
    margin: 0px 0px 0px 0px;
    padding: 0px;
    position:relative;
    left: 0px;
}
.footer_seccion_categoria{
    text-align: left;
    width: 75%;
    height: auto;
    float:left;
    color: #ffffff;
    padding-top: 15px;
    padding-left: 10px;
    padding-bottom: 10px;
}
.footer_seccion_categoria ul{
    padding: 0; /* Remueve el padding predeterminado */
    list-style: none; /* Elimina los marcadores de lista */
    display: flex;
}

.footer_seccion_categoria ul li{
    width: 120px;
    height:100px;
    background-color: var(--Fondo_Gris);
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    text-align: center;
    border: var(--Texto_Gris_4) solid 2px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
    transition: transform 0.1s ease, box-shadow 0.1s ease; /* Suaviza la transición de transformación y sombra */
}
.footer_seccion_categoria ul li:hover{
    transform: translate(2px, 2px); /* Mueve el elemento 5px a la derecha y 5px abajo */
    box-shadow: none; /* Elimina la sombra */
}

.footer_seccion_categoria ul li.control_y_fuerza{
    background-color: var(--Cat_ControlYFuerza);
}
.footer_seccion_categoria ul li.conductores{
    background-color: var(--Cat_Conductores);
}
.footer_seccion_categoria ul li.media_tension{
    background-color: var(--Cat_MediaTension);
}
.footer_seccion_categoria ul li.baja_tension{
    background-color: var(--Cat_BajaTension);
}
.footer_seccion_categoria ul li.iluminacion{
    background-color: var(--Cat_Iluminacion);
}
.footer_seccion_categoria ul li.herramientas{
    background-color: var(--Cat_Herramientas);
    color: var(--Texto_Negro);
}
.footer_seccion_categoria ul li.herramientas a{
    color: var(--Texto_Negro);
}

.footer_seccion_categoria ul li a{
    font-family: system-ui;
    font-weight: bold;
    color: var(--Texto_Blanco);
    font-size: 12px;
    text-decoration: none;
}

.footer_seccion_categoria ul li a img{
    margin-top: -25px;
}
.footer_seccion {
    text-align: left;
    width: 25%;
    height: auto;
    float:left;
    color: #ffffff;
    padding-top: 15px;
    padding-left: 10px;
    padding-bottom: 10px;
}
.footer_seccion ul{
    list-style-type: none;
    padding: 0px;
    margin: 10 0 10 0;
}

.footer_seccion li {
    list-style-type: none;
    font-family: system-ui;
    font-size: 11px;
    font-weight: bold;
    vertical-align: Left;
    color: #ffffff;
    text-decoration: none;
    padding: 1px;
    border-bottom: var(--Texto_Gris_4) solid 1px;
}

.footer_seccion li a{
    list-style-type: none;
    font-family: system-ui;
    font-size: 11px;
    font-weight: bold;
    vertical-align: Left;
    color: #ffffff;
    text-decoration: none;
    display: flex;    
}
.footer_seccion li a img{
    margin-top: -9px; 
    margin-right: 3px;
}
.footer_seccion_titulo{
    font-family: system-ui;
    font-size: 16px;
    vertical-align: Left;
}

.footer_franja_superior{
    width: 100%;
    height: 10px;
    background-color: var(--Naranja_1);
    margin:0px 0px 0px 0px;
    padding: 7px 0px 0px 0px;
    position:relative;
    left: 0px;
    color: #ffffff;
    font-family: system-ui;
    font-size: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 700px){
    .footer_movile{
        display: none;
        
    } 
    .footer_desktop{
        display: block;
    }       
}