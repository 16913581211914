@import url('../../../Variables.css');

.Header_Logotipo{
    padding-left: 10px;
    display: none;
}
.Header_LogotipoMovile{
    padding-left: 10px;
    display: block;
}

@media (min-width: 700px){
    .Header_Logotipo{
        display: block;
    }
    .Header_LogotipoMovile{
        display: none;
        padding-left: 10px;
    }    
}
