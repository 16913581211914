@import url('../../../Variables.css');

input{
    display: flex;
    float: left;
    align-items: left;
    width: 250px;
    height: 20px;
    border-radius: 5px;
    background-color: var(--Fondo_Gris);    
    border: 1px solid var(--Fondo_Azul_5);
    padding-top: 3px;
    padding-bottom: 3px;
    margin-left: 5px;
    margin-top: 0px; 
}

img{
    float: left;
    padding-right: 0px;
    padding-left: 10px;
}
input:focus{
    background-color: #ffffff;
    border: 1px solid #a3c3f1;
    outline:none;
}

.HeaderFormulario_Movile{
    display: flex;
}

.HeaderFormulario_Desktop{
    display: none;
    font-family: system-ui;
    font-size: 14px;
    color: var(--Texto_Blanco);
}

@media (min-width: 700px){
    .HeaderFormulario_Movile{
        display: none;
    }    
    .HeaderFormulario_Desktop{
        display: flex;
    }
    img{
        float: left;
        padding-right: 5px;
    }
    input{
        display: flex;
        float: left;
        align-items: left;
        width: 350px;
        height: 20px;
        border-radius: 5px;
        background-color: var(--Fondo_Azul_5);    
        border: 1px solid var(--Fondo_Azul_4);
        padding-top: 3px;
        padding-bottom: 3px;
        margin-left: 5px;
        margin-top: 0px; 
    }

}