/* src/Components/ChatGPT/Chat.css */
@import url('../../Variables.css');

.carousel-arrow {
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  font-size: 20px;
  color: black;
  cursor: pointer;
  outline: none;
}

.carousel-arrow-left {
  left: 15px;
}

.carousel-arrow-right {
  right: 15px;
}

.carousel-close-button {
  position: absolute;
  top: 100px;
  right: 50px;
  background-color: var(--Cat_ControlYFuerza);
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 40px;
  cursor: pointer;
  z-index: 3;
  color: var(--Texto_Blanco);
}

.carousel-image-container {
  max-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.carousel-image {
  object-fit: contain;
}

/* Estilos para pantallas grandes */
@media (min-width: 1024px) {
  .carousel-image {
    max-height: 80vh;
    max-width: 80vw;
  }
}

/* Estilos para pantallas de celulares en modo retrato */
@media (max-width: 767px) and (orientation: portrait) {
  .carousel-image {
    max-width: 100vw;
    max-height: auto;
  }
}

/* Estilos para pantallas de celulares en modo paisaje */
@media (max-width: 767px) and (orientation: landscape) {
  .carousel-image {
    max-height: 100vh;
    max-width: auto;
  }
}

/* msg- son estilos de uso en los mensajes. */

.msg-container {
  border: 1px solid #c0c0c0;
  border-radius: 7px;
  height: 400px;
  overflow-y: scroll;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  background-image: url(/public/img/Whatsapp-Fondo.png);
}

.msg-item {
  text-align: left;
  margin: 10px 0;
}

.msg-item-user {
  text-align: right;
}

.msg-content {
  display: inline-block;
  text-align: left;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
}

.msg-content-user {
  background-color: #dcf8c6;
}

.msg-text {
  margin: 0;
}

.msg-image {
  max-width: 200px;
  display: block;
  margin: 10px 0;
  cursor: pointer;
}

/* src/Components/ChatGPT/ChatInput.css */
.envio-container {
  display: flex;
  align-items: center;
  margin-top: 1px;
}

.envio-input {
  flex-grow: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  height: 30px;
}

.envio-button {
  margin-left: 10px;
  padding: 5px 20px;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  height: 30px;
}

.envio-file-button {
  margin-left: 2px;
  cursor: pointer;
  width: 30px;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  text-align: left;
  background-color: #f0eeee;
}

.envio-file-input {
  display: none;
}

