.producto_Desktop{
    display: flex;
    background-color: #e9e9f2;
    width: 200px;
    height: 285px;
    border-radius: 10px;
    align-content: left;
    margin:0px;
    box-shadow: 3px 3px 5px #909090;
}

.producto_Desktop_figure{
    width: 200px;
    height: 200px;    
    margin: 0px; 
    padding:0;   
    background-color: black;
    color:#ffffff;
    position:relative;
    margin: 0px;
    border-radius: 10px;
    cursor:pointer;
}
.producto_Desktop_img{
    margin: 0px;
    padding:0px;
    align-content: left;
    width:100%;
    height:100%;
    object-fit: cover;
    border-radius: 10px;
}
.producto_Desktop_nombre{
    width:100%;
    position: relative;
    top:10px;
    float:none;
    margin: 0px;
    text-align: center;
    cursor:pointer;
    font-weight:bold;
    text-decoration:none;
}
.producto_Desktop_nombre a{
    font-family: system-ui;
    font-size: 13px;
    color: #000000;
    text-decoration:none;
}
.producto_Desktop_marcaCodigo ul{
    float:left;
    width:120px;
    position: relative;
    top:5px;
    font-family: system-ui;
    font-size: 12px;
    color: #535353;
    text-align: left;
    padding: 0px;
    margin:10px 0px 0px 10px;
    list-style: none;
}
.producto_Desktop_whatsapp{
    width: 155px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-family: system-ui;
    font-size: 11px;
    margin-top: 10px;
    padding: 2px;
    background-color: var(--Cat_ControlYFuerza);
}
.producto_Desktop_whatsapp a{
    color: var(--Texto_Blanco);
}
.producto_Desktop_whatsapp a img{
    margin-top: -5px;
}