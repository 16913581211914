.marcas-logotipo {
    display: flex;
    flex-wrap: nowrap; /* Cambiado de wrap a nowrap */
    justify-content: center;
    margin-top: 20px;
    text-align: center;
    width: 100%;
    overflow-x: auto; /* Añadir overflow-x para el scroll horizontal */
}
.marca-caja {
    width: 110px;
    height: 43px;
    border-radius: 5px;
    margin: 0px;
    display: block;
}
.marca-caja img{
    width: 110px;
    height: 41px;
}

  