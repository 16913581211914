.product-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .product-canvas {
    position: relative;
    width: 1200px; /* Ajusta el tamaño según sea necesario */
    height: 1200px; /* Ajusta el tamaño según sea necesario */
    border: 0px solid #ccc;
    margin: 0px;
    background: white; /* Asegurarse de que el fondo sea blanco */
  }
  
  .download-button {
    margin-left: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .layer1 {
    position: absolute;
    top: 200px;
    left: 100px;
    width: 70%;
    height: 70%;
    margin: 0px;
    padding: 0px;
  }

  .layer2 {    
    position: absolute;
    top: -2px;
    left: -2px;
    width: 101%;
    height: 101%;
    margin: 0px;
    padding: 0px;
  }
  
  .layer3, .layer4, .layer5 {
    position: absolute;
    padding: 5px;
  }
  
  .layer3 {
    bottom: 44px;
    left: 195px;
    font-size: 37px;
    color: #ffffff;
  }
  
  .layer4 {
    top: 1050px;
    left: 50px;    
    font-size: 28px;
    color: #0f0f0f;
  }
  
  .layer5 {
    width: 600px;
    top: 1050px;
    right: 50px;    
    font-size: 32px;
    color: #0f0f0f;
    text-align: right;
  }
  
  .highlight {

    background: rgba(0, 0, 255, 0.5); /* Sombreado azul con 50% de opacidad */
  }
  