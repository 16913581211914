.Home-Desktop{
    width: calc(100% - 130px);
    height: auto;
    display: flex;
    flex-wrap:wrap;
    gap: 20px;
    align-content: flex-start;
    padding: 20px;
}

