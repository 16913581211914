@import url('../../../Variables.css');

.HeaderLoginCarritoMovile{
    width: 35px;
    height: 48px;
    margin-top: 25px;
    padding-right: 10px;
    display: block;
}
.HeaderLoginCarritoDesktop{
    width: 200px;
    height: 48px;
    display: none;
}

@media (min-width: 700px){
    .HeaderLoginCarritoMovile{
        display: none;
    }
    .HeaderLoginCarritoDesktop{
        display: block;
    }
}