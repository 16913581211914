/* variables.css */
:root {
    /* Resoluciónes para @media */
      --resolucion-celular: 700px;
      --resolucion-tablets: 1000px;

    /*Paletas de colores*/
      /* Tonos Azules de Fondo*/
        --Fondo_Azul: #19355b;
        --Fondo_Azul_2: #0f2b49;
        --Fondo_Azul_3: #214c7a;
        --Fondo_Azul_4: #067ccc;
        --Fondo_Azul_5: #d0def1;
      /*Tono Gris de Fondo*/
        --Fondo_Gris: #ececec;
      /*Tonos de Texto*/
        --Texto_Negro: #000000;
        --Texto_Gris_1: #505050;
        --Texto_Gris_2: #6e6e6e;
        --Texto_Gris_3: #828282;
        --Texto_Gris_4: #969696;
        --Texto_Blanco: #ffffff;
      /*Tonos Naranja*/
        --Naranja_1: #f78508;
        --Naranja_2: #ffe596;

    /*Tonos de Categorías*/
      --Cat_ControlYFuerza: #009642;
      --Cat_Conductores: #000000;
      --Cat_MediaTension: #22386d;
      --Cat_BajaTension: #009ee2;
      --Cat_Iluminacion: #f78508;
      --Cat_Herramientas: #f8be01;
  }