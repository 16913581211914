.product-navigation {
    background-color: #f8f9fa;
    padding: 1rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column; /* Cambia a columna para centrar en vertical */
    align-items: center; /* Centra los elementos horizontalmente */
    width: 100%; /* Asegura que ocupe el ancho completo */
    box-sizing: border-box;
    text-align: center;
}

.breadcrumb-container {
    width: 100%; /* Asegura que ocupe el ancho dentro de la navegación */
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; /* Centra los elementos dentro del breadcrumb */
    padding: 0;
    margin: 0;
    list-style: none;
}

.breadcrumb-item {
    color: #6c757d;
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.2s ease;
}

.breadcrumb-item:hover {
    color: #007bff;
    text-decoration: underline;
}

.breadcrumb-item.active {
    color: #343a40;
    font-weight: 500;
}

.breadcrumb-separator {
    color: #6c757d;
    padding: 0 0.5rem;
}

.brand-logo {
    margin-top: 1rem;
    max-width: 150px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.brand-logo img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

@media (max-width: 768px) {
    .product-navigation {
        flex-direction: column;
        gap: 1rem;
        align-items: center;
    }

    .breadcrumb {
        font-size: 0.8rem;
    }

    .brand-logo {
        margin-top: 1rem;
    }
}
