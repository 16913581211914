@import url('../../../Variables.css');

.HeaderLogin{
    width: 200px;
    height: 24px;    
    background-color: var(--Fondo_Azul_2);
}
.HeaderLogin ul{
    width: 100%;
    font-family: system-ui;
    font-size: 12px;
    color:aliceblue;
    list-style: none;
    display: flex;
    padding: 0px;
    margin: 0px;  
}
.HeaderLogin ul li{
    padding-left: 10px;
    padding-top: 4px;
}
.HeaderLogin ul li a{
    text-decoration: none;
    color: var(--Texto_Blanco);
}
.HeaderLogin ul li img{
    margin-top: -10px;
}