@import url('../../../Variables.css');

.headerCategoriasBlog_Desktop{
    width: 100%;
    height: 22px;
    padding: 0px;
    display: none;
    justify-content: space-between;
    background-color: var(--Fondo_Gris);
    
}

.HeaderCategoriasBlog_Categorias ul{
    font-family: system-ui;
    height: 100%;
    font-size: 12px;
    font-weight: bold;
    color: var(--Texto_Gris_1);
    list-style: none;
    display: flex;
    margin: 0px;
    text-align: left;
    padding-left: 5px;
    padding-top: 0px;
    background-color: var(--Texto_Gris_4);
}
.HeaderCategoriasBlog_Categorias ul img{
    margin-top: -8px;
    padding-left: 3px;
}
.HeaderCategoriasBlog_Categorias ul li{
    border-right: 2px solid var(--Fondo_Gris);
    padding-right: 5px;   
    padding-top: 2px; 
}

.HeaderCategoriasBlog_Categorias ul li a{
    font-size: 12px;
    font-weight: bold;
    color: var(--Texto_Gris_1);
    text-decoration:none;
}

.HeaderCategoriasBlog_Categorias ul li a:hover{
    color: var(--Texto_Negro);
}
.HeaderCategoriasBlog_Categorias ul li a.active{
    color: var(--Texto_Blanco);
}
.HeaderCategoriasBlog_Categorias li:hover{
    background-color: var(--Texto_Gris_2);
    color: var(--Texto_Blanco); /* Cambia el color cuando el ratón pasa sobre el enlace o está activo */
}
.HeaderCategoriasBlog_Categorias li.active{
    background-color: var(--Naranja_1);
    color: var(--Texto_Blanco); /* Cambia el color cuando el ratón pasa sobre el enlace o está activo */
}

.headerCategoriasBlog_Blog ul{
    width: 190px;
    background-color: var(--Fondo_Azul_5);
    height: 19px;
    font-family: system-ui;
    font-size: 12px;
    font-weight: bold;
    color: var(--Texto_Gris_1);
    list-style: none;
    display: flex;
    margin: 0px;
    text-align: center;
    padding-left: 10px;
    padding-top: 3px;
}
.headerCategoriasBlog_Blog ul li{
    width: 50%;
}

@media (min-width: 700px){
    .headerCategoriasBlog_Desktop{
        display: flex;
    }
    .headerCategoriasBlog_Movile{
        display: none;
    }

}